
import { FormController, validationRules } from '@icepanel/app-form'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import * as analytics from '@/modules/team/helpers/analytics'
import { TeamModule } from '@/modules/team/store'

@Component({
  name: 'TeamNameEdit'
})
export default class extends Vue {
  teamModule = getModule(TeamModule, this.$store)

  @Prop() readonly teamId!: string

  formController = new FormController({
    initialModel: {
      name: ''
    },
    validationRules: {
      name: [
        ...validationRules.exists,
        ...validationRules.maxLength(128)
      ]
    }
  })

  get currentTeam () {
    return this.teamModule.teams.find(o => o.id === this.teamId)
  }

  created () {
    this.formController.submitHandler = model => {
      if (!this.currentTeam) {
        return
      }

      this.updateTeamName(model.name)
    }

    this.formController.setInitialModel({
      name: this.currentTeam?.name ?? ''
    })
    this.formController.resetModel()
  }

  async updateTeamName (name: string) {
    const team = this.teamModule.teams.find(o => o.id === this.teamId)
    const updateName = name || 'Unnamed team'

    if (team && team.name !== updateName) {
      await this.teamModule.teamUpdate({
        organizationId: team.organizationId,
        teamId: team.id,
        update: {
          name: updateName
        }
      })

      analytics.teamUpdate.track(this, {
        organizationId: [team.organizationId],
        teamId: [team.id],
        teamName: updateName
      })
    }

    this.$emit('finish-editing')
  }
}
